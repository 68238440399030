<template>
  <div class="dashboard-numeric">
    <span class="dashboard-numeric__amount" :class="variantClass">
      {{ formattedValue }}
    </span>
    <span class="dashboard-numeric__label">
      {{ label }}
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DashboardNumeric",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "number",
      validator: (val) => ["number", "money", "percent"].includes(val),
    },
    label: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: null,
      validator: (val) => ["success", "danger", "valueDepending"].includes(val),
    },
  },
  computed: {
    ...mapState({
      country: (state) => state.venues.selectedVenue?.country,
    }),
    formattedValue() {
      if (this.type === "number") {
        return this.value.toLocaleString("en-US").replaceAll(",", " ");
      } else if (this.type === "percent") {
        return `${this.value}%`;
      } else if (this.type === "money") {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        }).format(this.value / 100);
      }
      return this.value;
    },
    variantClass() {
      if (this.variant) {
        if (this.variant === "valueDepending") {
          return { "m-danger": this.value < 0, "m-success": this.value > 0 };
        } else {
          return this.value > 0 && `m-${this.variant}`;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-numeric {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 36px 24px;
  width: 100%;
  height: 100%;

  &__amount {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: $secondary-500;
    white-space: nowrap;

    &.m-success {
      color: $success;
    }

    &.m-danger {
      color: $danger;
    }

    @media (min-width: $media-tablet) {
      font-size: 32px;
      line-height: 48px;
    }
    @media (min-width: $media-laptop) {
      font-size: 40px;
      line-height: 64px;
    }
  }

  &__label {
    font-size: 12px;
    line-height: 100%;
    color: $secondary-500;
    text-align: center;
  }
}
</style>
