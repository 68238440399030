<template>
  <div class="dashboard-tile">
    <Icon
      v-if="isClickable"
      name="eye"
      color="secondary-400"
      class="dashboard-tile__eye"
      @click="$emit('open')"
    />
    <Icon
      v-if="tooltip"
      v-tooltip.left-end="{
        content: tooltip,
        offset: 4,
        trigger: 'hover click',
        classes: 'dashboard-tile__tooltip',
      }"
      name="info"
      color="secondary-400"
      class="dashboard-tile__info"
      @click.stop
    />
    <slot />
    <div v-if="isLoading" class="overlay-loader">
      <Loader color="primary" size="m" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardTile",
  props: {
    tooltip: {
      type: String,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.dashboard-tile__tooltip {
  max-width: 300px;
  text-align: center;
  z-index: 2;
}
</style>

<style lang="scss" scoped>
.dashboard-tile {
  position: relative;
  background-color: $white;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;
  border-radius: 8px;

  &__eye {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index: 1;
  }

  &__info {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
}
</style>
