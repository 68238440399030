<template>
  <Doughnut
    :chart-data="configWithData"
    :chart-options="doughnutOptions"
    css-classes="dashboard-doughnut"
  />
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Title, Tooltip, Legend);

export default {
  name: "DashboardDoughnut",
  components: { Doughnut },
  props: {
    value: {
      type: [Array],
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default: null,
    },
  },
  computed: {
    configWithData() {
      if (this.value && this.config) {
        const configCopy = JSON.parse(JSON.stringify(this.config));
        configCopy.datasets[0].data = this.value;
        return configCopy;
      }
      return this.config;
    },
    doughnutOptions() {
      const [titleSize, labelSize] = ["xs", "sm"].includes(this.$mq)
        ? [12, 12]
        : [18, 16];
      const padding = -10;
      return {
        cutout: "80%",
        layout: {
          padding: {
            bottom: padding,
            left: padding,
            right: padding,
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.label,
            position: "top",
            align: "center",
            font: {
              size: titleSize,
              weight: 400,
            },
            color: "#2C3B55",
          },
          legend: {
            position: "bottom",
            align: "start",
            labels: {
              boxWidth: 8,
              boxHeight: 8,
              usePointStyle: true,
              font: {
                size: labelSize,
                weight: 400,
              },
            },
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
.dashboard-doughnut {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 110%;
  height: 90%;

  canvas {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain;
  }
}
</style>
