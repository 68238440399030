<template>
  <div class="dashboard-page">
    <div class="dashboard-page__flex-space-between">
      <Title class="mb-32">Dashboard</Title>
      <div class="dashboard-page__date-toggle">
        Filter by event date
        <Switcher
          v-model="isDateFieldBookingAt"
          label="Filter by booking date"
        />
      </div>
    </div>
    <div class="dashboard-page__flex-space-between mb-24">
      <Title :level="2">Statistics</Title>
      <div v-if="lastFetchedAt" class="dashboard-page__extra-action">
        Current as of {{ lastFetchedAt }}
        <Icon
          name="reload"
          is-clickable
          color="secondary-400"
          @click="refetchStatistics(true)"
        />
      </div>
    </div>
    <div class="grid-2 mb-40">
      <Select
        v-model="activeProductFilterId"
        :options="productOptions"
        label="Product"
      />
      <div class="grid-2__full-item dashboard-page__flex-space-between">
        <span class="dashboard-page__dates">
          {{ dateRangeFilterString }}
        </span>
        <SelectButtons v-model="activePeriodFilter" :options="rangeOptions" />
      </div>
    </div>
    <Loader v-if="isStatsLoading" class="mx-auto" size="m" color="primary" />
    <div v-else-if="statistics" class="dashboard-page__tiles">
      <DashboardTile
        v-for="(stat, key) in stats"
        :key="key"
        :tooltip="stat.tooltip"
        :is-clickable="stat.isClickable"
        :style="{
          'grid-column': stat.columnSpan && `span ${stat.columnSpan}`,
          'grid-row': stat.rowSpan && `span ${stat.rowSpan}`,
        }"
        @open="handleOpenStat(key)"
      >
        <component
          :is="stat.component"
          v-bind="stat.componentProps"
          :value="
            stat.formatter ? stat.formatter(statistics[key]) : statistics[key]
          "
        />
      </DashboardTile>
    </div>
    <div class="divider my-32" />
    <div class="dashboard-page__flex-space-between mb-24">
      <Title :level="2">Stripe balance</Title>
      <div class="dashboard-page__extra-action">
        View payouts
        <Icon
          name="eye"
          color="secondary-400"
          is-clickable
          @click="goToStripeBalanceDetails"
        />
      </div>
    </div>
    <Loader v-if="isBalanceLoading" class="mx-auto" size="m" color="primary" />
    <div v-else-if="stripeBalance" class="dashboard-page__tiles">
      <DashboardTile tooltip="Funds that are available to be paid out">
        <DashboardNumeric
          :value="stripeBalance.available"
          type="money"
          label="Available"
          variant="valueDepending"
        />
      </DashboardTile>
      <DashboardTile
        tooltip="Funds that are not yet available in the balance, due to the 7-day rolling pay cycle"
      >
        <DashboardNumeric
          :value="stripeBalance.pending"
          type="money"
          label="Pending"
          variant="valueDepending"
        />
      </DashboardTile>
    </div>
    <DatePeriodModal v-model="dateRangeFilter" default-label="Set date range" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SelectButtons from "@/components/common/SelectButtons";
import {
  getDateRangeByType,
  getTimezoneOffsetHours,
  getVenueTimeFormat,
} from "@/helpers/utils";
import DashboardTile from "@/components/dashboard/DashboardTile";
import { dateRangeOptions } from "@/helpers/mocks";
import moment from "moment";
import { DashboardStatsConfig } from "@/helpers/const";
import DashboardDoughnut from "@/components/dashboard/DashboardDoughnut";
import DashboardNumeric from "@/components/dashboard/DashboardNumeric";
import DashboardBounceRate from "@/components/dashboard/DashboardBounceRate";
import { RangeButtonsEnum } from "@/helpers/enums";
import DatePeriodModal from "@/components/bookings/DatePeriodModal.vue";

export default {
  name: "DashboardPage",
  components: {
    DashboardTile,
    SelectButtons,
    DashboardDoughnut,
    DashboardNumeric,
    DashboardBounceRate,
    DatePeriodModal,
  },
  data() {
    return {
      detailsModalName: "dashboardDetailsModal",
      detailsTitle: "",
      isStatsLoading: false,
      isBalanceLoading: false,
      stats: DashboardStatsConfig,
    };
  },
  computed: {
    ...mapState({
      allStatistics: (state) => state.dashboard.statistics,
      statistics: (state) => {
        const periodKey =
          state.dashboard.periodFilter !== RangeButtonsEnum.CUSTOM
            ? state.dashboard.periodFilter
            : state.dashboard.dateRangeFilter.join(",");
        return state.dashboard.statistics[periodKey];
      },
      lastFetchedAt: (state) => {
        const periodKey =
          state.dashboard.periodFilter !== RangeButtonsEnum.CUSTOM
            ? state.dashboard.periodFilter
            : state.dashboard.dateRangeFilter.join(",");
        const lastFetchedAt = state.dashboard.lastFetchedAt[periodKey];
        const timeFormat = getVenueTimeFormat();
        const offset = getTimezoneOffsetHours(
          state.venues.selectedVenue.timezone
        );
        return (
          lastFetchedAt &&
          moment
            .utc(lastFetchedAt, "X")
            .add(offset, "h")
            .format(`${timeFormat}, MMM D, YYYY`)
        );
      },
      stripeBalance: (state) => state.dashboard.stripeBalance,
      products: (state) => state.products.list,
      productFilterId: (state) => state.dashboard.productFilterId,
      periodFilter: (state) => state.dashboard.periodFilter,
      dateField: (state) => state.dashboard.dateField,
    }),
    rangeOptions() {
      return [...dateRangeOptions].filter((option) => {
        return (
          option.value in this.allStatistics ||
          option.value === RangeButtonsEnum.CUSTOM
        );
      });
    },
    productOptions() {
      return [
        {
          name: "All",
          value: null,
        },
        ...this.products.map((item) => ({
          name: item.name,
          value: item.id,
        })),
      ];
    },
    activeProductFilterId: {
      get() {
        return this.productFilterId;
      },
      set(newValue) {
        if (this.activeProductFilterId !== newValue) {
          this.SET_PRODUCT_FILTER_ID(newValue);
          this.resetStatisticsCache();
          this.refetchStatistics();
        }
      },
    },
    activePeriodFilter: {
      get() {
        return this.periodFilter;
      },
      set(newValue) {
        if (newValue === RangeButtonsEnum.CUSTOM) {
          this.$modal.show("datePeriodModal");
        } else if (this.activePeriodFilter !== newValue) {
          this.SET_PERIOD_FILTER(newValue);
          let dateRange = getDateRangeByType(newValue, "YYYY-MM-DD");
          if (dateRange) {
            this.SET_DATE_RANGE_FILTER(dateRange);
            this.refetchStatistics();
          }
        }
      },
    },
    dateRangeFilter: {
      get() {
        return this.$store.state.dashboard.dateRangeFilter;
      },
      set(newValue) {
        this.SET_PERIOD_FILTER(RangeButtonsEnum.CUSTOM);
        this.$store.commit("dashboard/SET_DATE_RANGE_FILTER", newValue);
        this.refetchStatistics();
      },
    },
    dateRangeFilterString() {
      const [from, to] = this.dateRangeFilter;
      const formattedFrom = moment(from).format("MM.DD.YYYY");
      if (from === to) {
        return formattedFrom;
      } else {
        const formattedTo = moment(to).format("MM.DD.YYYY");
        return `${formattedFrom} - ${formattedTo}`;
      }
    },
    isDateFieldBookingAt: {
      get() {
        return this.dateField === "bookingAt";
      },
      set(isBookingAt) {
        if (this.isDateFieldBookingAt !== isBookingAt) {
          this.resetStatisticsCache();
          this.SET_DATE_FIELD(isBookingAt ? "bookingAt" : "createdAt");
          this.refetchStatistics();
        }
      },
    },
  },
  async created() {
    await Promise.allSettled([
      this.refetchStatistics(),
      this.refetchStripeBalance(),
    ]);
  },
  methods: {
    ...mapActions({
      fetchStatistics: "dashboard/fetchStatistics",
      fetchDetailsList: "dashboard/fetchDetailsList",
      fetchStripeBalance: "dashboard/fetchStripeBalance",
      resetStatisticsCache: "dashboard/resetStatisticsCache",
    }),
    ...mapMutations({
      SET_PRODUCT_FILTER_ID: "dashboard/SET_PRODUCT_FILTER_ID",
      SET_DATE_RANGE_FILTER: "dashboard/SET_DATE_RANGE_FILTER",
      SET_PERIOD_FILTER: "dashboard/SET_PERIOD_FILTER",
      SET_DATE_FIELD: "dashboard/SET_DATE_FIELD",
    }),
    async refetchStatistics(isForceFetch) {
      try {
        this.isStatsLoading = true;
        await this.fetchStatistics(isForceFetch);
      } finally {
        this.isStatsLoading = false;
      }
    },
    async refetchStripeBalance() {
      try {
        this.isBalanceLoading = true;
        await this.fetchStripeBalance();
      } finally {
        this.isBalanceLoading = false;
      }
    },
    handleOpenStat(statKey) {
      this.$router.push({
        name: "DashboardStatisticsDetails",
        params: {
          statKey,
        },
      });
    },
    goToStripeBalanceDetails() {
      this.$router.push({
        name: "StripeBalanceDetails",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    flex-wrap: wrap;
  }

  &__date-toggle {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-500;
    font-weight: 400;
  }

  &__dates {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: $secondary-500;
  }

  &__extra-action {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-400;
  }

  &__tiles {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    grid-auto-rows: 1fr;

    @media (min-width: $media-tablet) {
      gap: 20px;
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: $media-desktop) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
</style>
