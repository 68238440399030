<template>
  <div class="dashboard-bounce-rate">
    <div class="dashboard-bounce-rate__title">Purchase funnel</div>
    <div class="dashboard-bounce-rate__chart">
      <template v-for="(item, index) in rates">
        <div
          :key="`percent-${item.interaction}`"
          class="dashboard-bounce-rate__chart__percent"
        >
          {{ item.percent }}%
        </div>
        <div
          :key="`label-${item.interaction}`"
          class="dashboard-bounce-rate__chart__label"
        >
          {{ item.label }}
        </div>
        <div
          class="dashboard-bounce-rate__chart__bar-wrapper"
          :key="`bar-${item.interaction}`"
        >
          <div
            class="dashboard-bounce-rate__chart__bar"
            :style="{
              opacity: (1 / rates.length) * (index + 1),
              width: `${item.percent}%`,
            }"
          />
        </div>
        <div
          :key="`number-${item.interaction}`"
          class="dashboard-bounce-rate__chart__number"
        >
          {{ item.number }}
        </div>
      </template>
    </div>
    <div class="dashboard-bounce-rate__bottom">
      <span class="dashboard-bounce-rate__rate">
        Conversion rate&nbsp;
        <span class="dashboard-bounce-rate__rate__value">
          {{ widgetVisitors ? 100 - value.bounceRate : 0 }}%
        </span>
      </span>
      <span class="dashboard-bounce-rate__rate">
        Bounce rate&nbsp;
        <span class="dashboard-bounce-rate__rate__value">
          {{ value.bounceRate }}%
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { BookingInteractionEnum } from "@/helpers/enums";

export default {
  name: "DashboardBounceRate",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    widgetVisitors() {
      return this.value.interactions[BookingInteractionEnum.OPEN];
    },
    rates() {
      return Object.entries(this.value.interactions).map(
        ([interaction, number]) => ({
          interaction,
          number,
          percent:
            this.widgetVisitors &&
            Math.round((number / this.widgetVisitors) * 100),
          label: {
            [BookingInteractionEnum.OPEN]: "widget visitors",
            [BookingInteractionEnum.SELECT_PRODUCT]: "product picked",
            [BookingInteractionEnum.PLAYERS_COUNT]: "players picked",
            [BookingInteractionEnum.SELECT_SLOTS]: "date picked",
            [BookingInteractionEnum.REQUEST_PAYMENT]: "payment",
            [BookingInteractionEnum.PAYMENT_SUCCESS]: "paid",
          }[interaction],
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-bounce-rate {
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__bottom {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: auto;
  }

  &__title {
    font-size: 12px;
    line-height: 12px;
    color: $secondary-500;
    margin-bottom: 8px;
  }

  &__rate {
    font-size: 12px;
    line-height: 24px;
    color: $secondary-500;

    &__value {
      color: $success;
    }
  }

  &__chart {
    display: grid;
    grid-template-columns: min-content min-content 1fr min-content;

    > * {
      padding-top: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $secondary-400;
      display: flex;
      align-items: center;
    }

    &__percent {
      font-size: 14px;
      line-height: 100%;
      color: $primary;
    }

    &__label {
      font-size: 12px;
      line-height: 100%;
      color: $secondary-400;
      white-space: nowrap;
      padding-left: 10px;
    }

    &__number {
      font-weight: 500;
      font-size: 24px;
      line-height: 100%;
      color: $secondary-500;
      justify-content: flex-end;
    }

    &__bar-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      padding-left: 24px;
      padding-right: 24px;
    }

    &__bar {
      background-color: $primary;
      margin-left: auto;
      height: 100%;
    }
  }
}
</style>
